import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaterialColorService {
  colors: { [key: string]: string } = {};
  private colorList = `
mat-red-50 rgb(251,233,231)
mat-red-100 rgb(244,199,195)
mat-red-200 rgb(237,162,155)
mat-red-300 rgb(230,124,115)
mat-red-400 rgb(224,96,85)
mat-red-500 rgb(219,68,55)
mat-red-600 rgb(210,63,49)
mat-red-700 rgb(197,57,41)
mat-red-800 rgb(185,50,33)
mat-red-900 rgb(165,39,20)
mat-red-A100 rgb(255,138,128)
mat-red-A200 rgb(255,82,82)
mat-red-A400 rgb(255,23,68)
mat-red-A700 rgb(213,0,0)
mat-pink-50 rgb(252,228,236)
mat-pink-100 rgb(248,187,208)
mat-pink-200 rgb(244,143,177)
mat-pink-300 rgb(240,98,146)
mat-pink-400 rgb(236,64,122)
mat-pink-500 rgb(233,30,99)
mat-pink-600 rgb(216,27,96)
mat-pink-700 rgb(194,24,91)
mat-pink-800 rgb(173,20,87)
mat-pink-900 rgb(136,14,79)
mat-pink-A100 rgb(255,128,171)
mat-pink-A200 rgb(255,64,129)
mat-pink-A400 rgb(245,0,87)
mat-pink-A700 rgb(197,17,98)
mat-purple-50 rgb(243,229,245)
mat-purple-100 rgb(225,190,231)
mat-purple-200 rgb(206,147,216)
mat-purple-300 rgb(186,104,200)
mat-purple-400 rgb(171,71,188)
mat-purple-500 rgb(156,39,176)
mat-purple-600 rgb(142,36,170)
mat-purple-700 rgb(123,31,162)
mat-purple-800 rgb(106,27,154)
mat-purple-900 rgb(74,20,140)
mat-purple-A100 rgb(234,128,252)
mat-purple-A200 rgb(224,64,251)
mat-purple-A400 rgb(213,0,249)
mat-purple-A700 rgb(170,0,255)
mat-deep-purple-50 rgb(237,231,246)
mat-deep-purple-100 rgb(209,196,233)
mat-deep-purple-200 rgb(179,157,219)
mat-deep-purple-300 rgb(149,117,205)
mat-deep-purple-400 rgb(126,87,194)
mat-deep-purple-500 rgb(103,58,183)
mat-deep-purple-600 rgb(94,53,177)
mat-deep-purple-700 rgb(81,45,168)
mat-deep-purple-800 rgb(69,39,160)
mat-deep-purple-900 rgb(49,27,146)
mat-deep-purple-A100 rgb(179,136,255)
mat-deep-purple-A200 rgb(124,77,255)
mat-deep-purple-A400 rgb(101,31,255)
mat-deep-purple-A700 rgb(98,0,234)
mat-indigo-50 rgb(232,234,246)
mat-indigo-100 rgb(197,202,233)
mat-indigo-200 rgb(159,168,218)
mat-indigo-300 rgb(121,134,203)
mat-indigo-400 rgb(92,107,192)
mat-indigo-500 rgb(63,81,181)
mat-indigo-600 rgb(57,73,171)
mat-indigo-700 rgb(48,63,159)
mat-indigo-800 rgb(40,53,147)
mat-indigo-900 rgb(26,35,126)
mat-indigo-A100 rgb(140,158,255)
mat-indigo-A200 rgb(83,109,254)
mat-indigo-A400 rgb(61,90,254)
mat-indigo-A700 rgb(48,79,254)
mat-blue-50 rgb(232,240,254)
mat-blue-100 rgb(198,218,252)
mat-blue-200 rgb(161,194,250)
mat-blue-300 rgb(123,170,247)
mat-blue-400 rgb(94,151,246)
mat-blue-500 rgb(66,133,244)
mat-blue-600 rgb(59,120,231)
mat-blue-700 rgb(51,103,214)
mat-blue-800 rgb(42,86,198)
mat-blue-900 rgb(28,58,169)
mat-blue-A100 rgb(130,177,255)
mat-blue-A200 rgb(68,138,255)
mat-blue-A400 rgb(41,121,255)
mat-blue-A700 rgb(41,98,255)
mat-light-blue-50 rgb(225,245,254)
mat-light-blue-100 rgb(179,229,252)
mat-light-blue-200 rgb(129,212,250)
mat-light-blue-300 rgb(79,195,247)
mat-light-blue-400 rgb(41,182,246)
mat-light-blue-500 rgb(3,169,244)
mat-light-blue-600 rgb(3,155,229)
mat-light-blue-700 rgb(2,136,209)
mat-light-blue-800 rgb(2,119,189)
mat-light-blue-900 rgb(1,87,155)
mat-light-blue-A100 rgb(128,216,255)
mat-light-blue-A200 rgb(64,196,255)
mat-light-blue-A400 rgb(0,176,255)
mat-light-blue-A700 rgb(0,145,234)
mat-cyan-50 rgb(224,247,250)
mat-cyan-100 rgb(178,235,242)
mat-cyan-200 rgb(128,222,234)
mat-cyan-300 rgb(77,208,225)
mat-cyan-400 rgb(38,198,218)
mat-cyan-500 rgb(0,188,212)
mat-cyan-600 rgb(0,172,193)
mat-cyan-700 rgb(0,151,167)
mat-cyan-800 rgb(0,131,143)
mat-cyan-900 rgb(0,96,100)
mat-cyan-A100 rgb(132,255,255)
mat-cyan-A200 rgb(24,255,255)
mat-cyan-A400 rgb(0,229,255)
mat-cyan-A700 rgb(0,184,212)
mat-teal-50 rgb(224,242,241)
mat-teal-100 rgb(178,223,219)
mat-teal-200 rgb(128,203,196)
mat-teal-300 rgb(77,182,172)
mat-teal-400 rgb(38,166,154)
mat-teal-500 rgb(0,150,136)
mat-teal-600 rgb(0,137,123)
mat-teal-700 rgb(0,121,107)
mat-teal-800 rgb(0,105,92)
mat-teal-900 rgb(0,77,64)
mat-teal-A100 rgb(167,255,235)
mat-teal-A200 rgb(100,255,218)
mat-teal-A400 rgb(29,233,182)
mat-teal-A700 rgb(0,191,165)
mat-green-50 rgb(226,243,235)
mat-green-100 rgb(183,225,205)
mat-green-200 rgb(135,206,172)
mat-green-300 rgb(87,187,138)
mat-green-400 rgb(51,172,113)
mat-green-500 rgb(15,157,88)
mat-green-600 rgb(13,144,79)
mat-green-700 rgb(11,128,67)
mat-green-800 rgb(9,113,56)
mat-green-900 rgb(5,85,36)
mat-green-A100 rgb(185,246,202)
mat-green-A200 rgb(105,240,174)
mat-green-A400 rgb(0,230,118)
mat-green-A700 rgb(0,200,83)
mat-light-green-50 rgb(241,248,233)
mat-light-green-100 rgb(220,237,200)
mat-light-green-200 rgb(197,225,165)
mat-light-green-300 rgb(174,213,129)
mat-light-green-400 rgb(156,204,101)
mat-light-green-500 rgb(139,195,74)
mat-light-green-600 rgb(124,179,66)
mat-light-green-700 rgb(104,159,56)
mat-light-green-800 rgb(85,139,47)
mat-light-green-900 rgb(51,105,30)
mat-light-green-A100 rgb(204,255,144)
mat-light-green-A200 rgb(178,255,89)
mat-light-green-A400 rgb(118,255,3)
mat-light-green-A700 rgb(100,221,23)
mat-lime-50 rgb(249,251,231)
mat-lime-100 rgb(240,244,195)
mat-lime-200 rgb(230,238,156)
mat-lime-300 rgb(220,231,117)
mat-lime-400 rgb(212,225,87)
mat-lime-500 rgb(205,220,57)
mat-lime-600 rgb(192,202,51)
mat-lime-700 rgb(175,180,43)
mat-lime-800 rgb(158,157,36)
mat-lime-900 rgb(130,119,23)
mat-lime-A100 rgb(244,255,129)
mat-lime-A200 rgb(238,255,65)
mat-lime-A400 rgb(198,255,0)
mat-lime-A700 rgb(174,234,0)
mat-yellow-50 rgb(254,246,224)
mat-yellow-100 rgb(252,232,178)
mat-yellow-200 rgb(250,218,128)
mat-yellow-300 rgb(247,203,77)
mat-yellow-400 rgb(246,191,38)
mat-yellow-500 rgb(244,180,0)
mat-yellow-600 rgb(242,166,0)
mat-yellow-700 rgb(240,147,0)
mat-yellow-800 rgb(238,129,0)
mat-yellow-900 rgb(234,97,0)
mat-yellow-A100 rgb(255,222,128)
mat-yellow-A200 rgb(255,205,64)
mat-yellow-A400 rgb(255,188,0)
mat-yellow-A700 rgb(255,158,0)
mat-orange-50 rgb(255,243,224)
mat-orange-100 rgb(255,224,178)
mat-orange-200 rgb(255,204,128)
mat-orange-300 rgb(255,183,77)
mat-orange-400 rgb(255,167,38)
mat-orange-500 rgb(255,152,0)
mat-orange-600 rgb(251,140,0)
mat-orange-700 rgb(245,124,0)
mat-orange-800 rgb(239,108,0)
mat-orange-900 rgb(230,81,0)
mat-orange-A100 rgb(255,209,128)
mat-orange-A200 rgb(255,171,64)
mat-orange-A400 rgb(255,145,0)
mat-orange-A700 rgb(255,109,0)
mat-deep-orange-50 rgb(251,233,231)
mat-deep-orange-100 rgb(255,204,188)
mat-deep-orange-200 rgb(255,171,145)
mat-deep-orange-300 rgb(255,138,101)
mat-deep-orange-400 rgb(255,112,67)
mat-deep-orange-500 rgb(255,87,34)
mat-deep-orange-600 rgb(244,81,30)
mat-deep-orange-700 rgb(230,74,25)
mat-deep-orange-800 rgb(216,67,21)
mat-deep-orange-900 rgb(191,54,12)
mat-deep-orange-A100 rgb(255,158,128)
mat-deep-orange-A200 rgb(255,110,64)
mat-deep-orange-A400 rgb(255,61,0)
mat-deep-orange-A700 rgb(221,44,0)
mat-brown-50 rgb(239,235,233)
mat-brown-100 rgb(215,204,200)
mat-brown-200 rgb(188,170,164)
mat-brown-300 rgb(161,136,127)
mat-brown-400 rgb(141,110,99)
mat-brown-500 rgb(121,85,72)
mat-brown-600 rgb(109,76,65)
mat-brown-700 rgb(93,64,55)
mat-brown-800 rgb(78,52,46)
mat-brown-900 rgb(62,39,35)
mat-grey-50 rgb(250,250,250)
mat-grey-100 rgb(245,245,245)
mat-grey-200 rgb(238,238,238)
mat-grey-300 rgb(224,224,224)
mat-grey-400 rgb(189,189,189)
mat-grey-500 rgb(158,158,158)
mat-grey-600 rgb(117,117,117)
mat-grey-700 rgb(97,97,97)
mat-grey-800 rgb(66,66,66)
mat-grey-900 rgb(33,33,33)
mat-blue-grey-50 rgb(236,239,241)
mat-blue-grey-100 rgb(207,216,220)
mat-blue-grey-200 rgb(176,190,197)
mat-blue-grey-300 rgb(144,164,174)
mat-blue-grey-400 rgb(120,144,156)
mat-blue-grey-500 rgb(96,125,139)
mat-blue-grey-600 rgb(84,110,122)
mat-blue-grey-700 rgb(69,90,100)
mat-blue-grey-800 rgb(55,71,79)
mat-blue-grey-900 rgb(38,50,56)
mat-amber-50 rgb(255,248,225)
mat-amber-100 rgb(255,236,179)
mat-amber-200 rgb(255,224,130)
mat-amber-300 rgb(255,213,79)
mat-amber-400 rgb(255,202,40)
mat-amber-500 rgb(255,193,7)
mat-amber-600 rgb(255,179,0)
mat-amber-700 rgb(255,160,0)
mat-amber-800 rgb(255,143,0)
mat-amber-900 rgb(255,111,0)
mat-amber-A100 rgb(255,229,127)
mat-amber-A200 rgb(255,215,64)
mat-amber-A400 rgb(255,196,0)
mat-amber-A700 rgb(255,171,0)
`;

  constructor() {
    const map = this.colorList.split('\n').map(entry => {
      const parts = entry.split(' ');
      if (parts[1] != null) {
        const colors = parts[1].replace('rgb(', '').replace(')', '').split(',');
        const color = (+colors[0]) * 65536 + (+colors[1]) * 256 + (+colors[2]);
        return {key: parts[0], value: color.toString(16).padStart(6, '0')};
      }
      return null;
    });
    for (const entry of map) {
      if (entry?.value != null) {
        this.colors[entry.key] = `#${entry.value}`;
      }
    }
  }
}
