<div cdkDrag cdkDragBoundary=".cdk-overlay-container"
     cdkDragRootElement=".dialog-box.colorpicker">
  <div cdkDragHandle mat-dialog-title>
    <h1>
      <div i18n>Colorselection</div>
    </h1>
    <button (click)="clickClose()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <button (click)="clickMode()" mat-icon-button>
      <mat-icon>{{modeIcon}}</mat-icon>
    </button>
    <ng-container *ngIf="data.mode === 'image'">
      <app-color-picker-image (colorClick)="currentColorClick($event)" [(color)]="currentColor" [data]="data" [parentClick]="fire"></app-color-picker-image>
    </ng-container>
    <ng-container *ngIf="data.mode === 'mixer'">
      <app-color-picker-mixer (colorClick)="currentColorClick($event)" [(color)]="currentColor" [data]="data" [parentClick]="fire"></app-color-picker-mixer>
    </ng-container>
    <ng-container *ngIf="data.mode === 'rgb'">
      <app-color-picker-rgb (colorClick)="currentColorClick($event)" [(color)]="currentColor" [data]="data" [parentClick]="fire"></app-color-picker-rgb>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div (click)="colorClick($event, data.color)"
         [ngStyle]="{backgroundColor:data.color.display,marginRight:'1em'}"
         class="color"></div>
    <div (click)="colorClick($event, color)"
         *ngFor="let color of savedColors; let idx = index"
         [class]="classForCurrColor(idx)"
         [ngStyle]="{backgroundColor:color.display}"></div>
    <div spacer></div>
    <ng-container *ngIf="data.mode === 'image'">
      <button (click)="fire.emit('File')" mat-icon-button>
        <mat-icon>upload</mat-icon>
      </button>
    </ng-container>
  </mat-dialog-actions>
</div>
