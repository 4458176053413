<input #imgSelect (change)="fileSelected($event)" accept="image/png,image/gif,image/jpeg,image/apng,image/avif,image/webp" type="file">
<div #canvasBox image>
  <div *ngIf="data.imageDataUrl == null" i18n>
    With the button to the bottom right an image<br>can be loaded. From this you can<br>select a color.
  </div>
  <canvas #canvasImage
          (click)="clickCanvas($event)"
          (mouseenter)="canvasMouseEnter($event)"
          (mousemove)="paintLens($event)"
          (mouseout)="canvasMouseOut($event)"
          (wheel)="wheelLens($event)"
          *ngIf="data.imageDataUrl != null"></canvas>
</div>
<canvas #canvasLens class="lens" height="100"
        width="100"></canvas>
<img #img [src]="data.imageDataUrl" alt="" class="img" id="img">
