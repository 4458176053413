<div mainpanel>
  <div toolbar>
    <div>
      <button (click)="globals.appSite=site.id"
              *ngFor="let site of globals.sites"
              [class]="site.id === globals.appSite ? 'current': ''"
              btn-site
              mat-button>
        <img alt="appicon" class="appicon" src="../../../assets/images/site-{{site.id}}.png">
      </button>
    </div>
    <div>
      <button [matMenuTriggerFor]="menu" mat-button>
        <img alt="appicon" class="appicon" src="../../../assets/themes/standard/favicon.png">
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button (click)="clickWhatsNew()" mat-menu-item>
          <mat-icon>info_outline</mat-icon>
        </button>
        <button (click)="clickImpressum()" mat-menu-item>
          <mat-icon>account_balance</mat-icon>
        </button>
        <button (click)="clickWelcome()" mat-menu-item>
          <mat-icon>school</mat-icon>
        </button>
        <div *ngFor="let item of ls.languageList; let idx = index" class="btnLanguage">
          <button (click)="clickLanguage(item)" [class]="languageClass(item)" mat-icon-button>
            <img alt="language image" class="language" src="{{item.imgPath}}">
          </button>
          <a *ngIf="item.crowdin != null" [href]="item.crowdin.languageUrl" class="crowdin" target="_blank" title="Crowdin">
            <img [src]="item.crowdin.languageBadgeUrl" alt="{{item.crowdin.langName}} proofreading">
          </a>
        </div>
      </mat-menu>
    </div>
    <div>
      <button (click)="clickDebug($event)" *ngIf="globals.mayDebug" class="debug" mat-icon-button>
        <mat-icon>{{globals.isDebug ? 'pest_control' : 'bug_report'}}</mat-icon>
      </button>
      <button (click)="sync.toggleSyncDropbox()" id="sync" mat-icon-button>
        <ng-container *ngIf="!sync.hasSync">
          <mat-icon>sync_disabled</mat-icon>
        </ng-container>
        <ng-container *ngIf="sync.hasSync">
          <img alt="dropbox" src="../../../assets/images/dropbox.png">
        </ng-container>
      </button>
    </div>
  </div>
  <div [class]="globals.appSite" [ngSwitch]="globals.appSite" content>
    <app-site-thumbling *ngSwitchCase="'thumb'"></app-site-thumbling>
    <app-site-prime-numbers *ngSwitchCase="'prime'"></app-site-prime-numbers>
    <app-site-rubik *ngSwitchCase="'rubik'"></app-site-rubik>
  </div>
</div>
<app-progress></app-progress>
<div hidden id="mark"></div>
