<div cdkDrag cdkDragBoundary=".cdk-overlay-container"
     cdkDragRootElement=".dialog-box.whatsnew">
  <div cdkDragHandle mat-dialog-title>
    <h1 i18n="title of whatsnew">Once upon a time...</h1>
    <div>
      <button mat-dialog-close="ok" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <h2 (click)="click(10.0)">
      <span i18n="version">Version</span><span i18n="v100">1.0.0 - 23/03/2023</span></h2>
    <ul [class]="classFor(10.0)">
      <li class="added" i18n="v100a1">
        Initial Version, everything is new!
      </li>
    </ul>
  </mat-dialog-content>
</div>
