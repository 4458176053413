<div cdkDrag cdkDragBoundary=".cdk-overlay-container"
     cdkDragRootElement=".dialog-box.welcome">
  <div cdkDragHandle mat-dialog-title>
    <h1 i18n="title of welcome">Welcome</h1>
    <div>
      <button mat-dialog-close="ok" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content>
    <section id="main">
      <div class="text">
        <section class="headline">Welcome to {{globals.appTitle}}</section>
      </div>
      <section class="title">What does <i>{{globals.appTitle}}</i> mean?</section>
      <section class="text">
        <i>Lisa</i> is a shortword for <i>Link Saver</i>.
      </section>
      <section class="title">What can i do here?</section>
      <section class="text">
      </section>
      <section class="title">How do i use this page?</section>
      <section class="text">
        <span (mouseout)="hideMark()" (mouseover)="showMark('#showchars')">Icon</span>
      </section>
      <section class="title">How do you get such a braindea...dicated idea?</section>
      <section class="text">
      </section>
    </section>
  </div>
</div>
