<div cdkDrag cdkDragBoundary=".cdk-overlay-container"
     cdkDragRootElement=".dialog-box.impressum">
  <div cdkDragHandle mat-dialog-title>
    <h1 i18n="title of impressum">Impressum</h1>
    <div>
      <button mat-dialog-close="ok" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content>
    <section>
      <section class="title">Impressum</section>
      Angaben gem&auml;ss &sect; 5 TMG<br><br>Andreas Perlitz<br>Karlsbader Str. 15<br>86169 Augsburg<br>
      <h2>Kontakt</h2>Telefon: 0821 / 704 705<br>E-Mail: <a href="mailto:andi@zreptil.de">andi@zreptil.de</a>
      <br>Internetadresse: <a href="https://blog.zreptil.de" target="_blank">https://blog.zreptil.de</a>
      <br>
      <section class="title">Haftungsausschluss</section>
      Haftung f&uuml;r Inhalte<br>Die Inhalte unserer Seiten wurden mit gr&ouml;sster Sorgfalt erstellt. F&uuml;r die Richtigkeit, Vollst&auml;ndigkeit und Aktualit&auml;t der Inhalte k&ouml;nnen wir
      jedoch keine Gew&auml;hr &uuml;bernehmen. Als Diensteanbieter sind wir gem&auml;ss &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
      &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu
      forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt.
      Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
      diese Inhalte umgehend entfernen.<br><br>Haftung f&uuml;r Links<br>Unser Angebot enth&auml;lt Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen
      wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
      Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;sse &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
      Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
      derartige Links umgehend entfernen.<br><br>Urheberrecht<br>Die durch die Seitenbetreiber erstellten bzw. verwendeten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.
      Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der Verwertung ausserhalb der Grenzen des Urheberrechtes bed&uuml;rfen der Zustimmung des jeweiligen Autors bzw. Erstellers.
      Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
      Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
      entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.<br><br><i>Quelle: </i><a href="http://www.muster-vorlagen.net" target="_blank">Impressum-Generator
      Muster-Vorlagen.net</a><br><br>
    </section>
  </div>
</div>
