<div *ngIf="links.length > 0 && globals.isDebug" links>
  <button (click)="onClickDelete($event, 'links')" close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <ng-container *ngFor="let link of links">
    <button (click)="openLink(link)" [class]="link.btnClass" mat-button>
      <mat-icon>{{link.icon}}</mat-icon>
      {{link.title}}
    </button>
  </ng-container>
</div>
<ng-container *ngFor="let type of ['error', 'info', 'debug', 'warn', 'todo']">
  <div *ngIf="showType(type)" [class]="type" content>
    <button (click)="onClickDelete($event, type)" delete mat-button>
      <mat-icon>delete</mat-icon>
      {{msg[type].length}}
    </button>
    <div lines>
      <ng-container *ngFor="let line of msg[type]">
        <div [class]="classForLine(line)">{{line | log}}</div>
      </ng-container>
    </div>
  </div>
</ng-container>
