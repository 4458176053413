<div (click)="clickMove($event)" frame>
  <div name>{{name}}</div>
  <div moves>
    <div (click)="clickCube($event)" class="cube">
      <div [style]="styleForCube" cube>
        <div *ngFor="let face of 'dbrlfu'.split('')" class="face {{face}}">
          <div class="plates">
            <ng-container *ngFor="let y of [0,1,2]">
              <div *ngFor="let x of [0,1,2]"
                   [style]="styleForPlate(face, x,y)"
                   cell>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="showMoves">
      <ng-container
        *ngFor="let moveId of moves"
        [ngTemplateOutletContext]="{moveId:moveId}"
        [ngTemplateOutlet]="tplMove">
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #tplMove let-moveId="moveId">
  <div root>
    <ng-container *ngIf="faceForMove(moveId) === 'f'">
      <div face flat>
        <ng-container *ngFor="let y of [0,1,2]">
          <div *ngFor="let x of [0,1,2]" cell></div>
        </ng-container>
        <div *ngIf="faceForMove(moveId) === 'f'" [class]="classForMove(moveId)" move>
          <mat-icon [class]="classForIcon(moveId)">{{iconForMove(moveId)}}</mat-icon>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="faceForMove(moveId) !== 'f'">
      <div face top>
        <ng-container *ngFor="let y of [0,1,2]">
          <div *ngFor="let x of [0,1,2]" cell></div>
        </ng-container>
        <div *ngIf="faceForMove(moveId) === 'u'" [class]="classForMove(moveId)" move>
          <mat-icon [class]="classForIcon(moveId)">{{iconForMove(moveId)}}</mat-icon>
        </div>
      </div>
      <div face front>
        <ng-container *ngFor="let y of [0,1,2]">
          <div *ngFor="let x of [0,1,2]" cell></div>
        </ng-container>
        <div *ngIf="faceForMove(moveId) === 'f'" [class]="classForMove(moveId)" move>
          <mat-icon [class]="classForIcon(moveId)">{{iconForMove(moveId)}}</mat-icon>
        </div>
      </div>
      <div face right>
        <ng-container *ngFor="let y of [0,1,2]">
          <div *ngFor="let x of [0,1,2]" cell></div>
        </ng-container>
        <div *ngIf="faceForMove(moveId) === 'r'" [class]="classForMove(moveId)" move>
          <mat-icon [class]="classForIcon(moveId)">{{iconForMove(moveId)}}</mat-icon>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
