<!--suppress HtmlDeprecatedAttribute -->
<div cdkDrag cdkDragBoundary=".cdk-overlay-container"
     cdkDragRootElement=".dialog-box.dialog">
  <div [style]="customStyle('Header')" cdkDragHandle class="type{{data?.type}}" mat-dialog-title>
    <h1>
      <mat-icon *ngIf="showTitleIcon">{{titleIcon}}</mat-icon>
      <img (error)="noImage($event)" *ngIf="showTitleImage" [src]="titleImage" alt="titleimage">
      {{data.title}}
    </h1>
    <div spacer></div>
    <button (click)="clickClose()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div [style]="customStyle('Body')" class="dialog-text" mat-dialog-content>
    <div *ngFor="let text of data.display" [innerHTML]="text"></div>
  </div>
  <div [style]="customStyle('Header')" align="end" class="type{{data?.type}}" mat-dialog-actions>
    <ng-container *ngFor="let btn of data.buttons; let idx = index">
      <button (click)="closeDialog(btn)" *ngIf="btn.url == null" mat-button>
        <mat-icon *ngIf="btn.icon != null">{{btn.icon}}</mat-icon>
        {{btn.title}}
      </button>
      <button (click)="openUrl(btn)" *ngIf="btn.url != null" mat-button>
        <mat-icon *ngIf="btn.icon != null">{{btn.icon}}</mat-icon>
        {{btn.title}}
      </button>
    </ng-container>
  </div>
</div>
